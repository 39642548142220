<!-- 模块说明:代理管理 -->
<template>
  <div class="container">
    <div class="view">
      <div class="flex">
        <div class="title">代理管理</div>
        <div class="flex">
          <el-date-picker
            class="waicengPhone"
            v-model="searchEle.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
          <el-input
            class="waicengPhone"
            v-model="searchEle.name"
            placeholder="请输入名称"
            clearable
            maxlength="11"
          ></el-input>
          <el-input
            class="waicengPhone"
            v-model="searchEle.phone"
            placeholder="请输入手机号"
            clearable
            maxlength="11"
          ></el-input>
          <el-button
            @click="(currentPage = 1), getAgencyManagementList()"
            type="primary"
          >
            查询
          </el-button>
          <el-button @click="addAgent(true)" type="primary">
            添加代理
          </el-button>
          <el-button @click="exportOn" type="primary" plain>
            导出数据
          </el-button>
        </div>
      </div>
      <el-table :data="tableData" stripe class="HNMR" @sort-change="sortchange">
        <el-table-column prop="name" label="代理名称"> </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>
        <el-table-column prop="videoTime" label="短视频" sortable="custom">
          <template slot-scope="scope">
            {{ minute(scope.row.videoTime) }}分钟
          </template>
        </el-table-column>
        <el-table-column prop="creditTime" label="无人直播" sortable="custom">
          <template slot-scope="scope">
            {{ minute(scope.row.creditTime) }}分钟
          </template>
        </el-table-column>
        <el-table-column prop="liveRPTime" label="真人驱动直播" sortable="custom">
          <template slot-scope="scope">
            {{ minute(scope.row.liveRPTime) }}分钟
          </template>
        </el-table-column>
        <el-table-column prop="tcSum" label="终端客户数量" sortable="custom">
          <template slot-scope="scope">
            <span
              @click="goTo(scope.row.amId)"
              style="color: #409eff; cursor: pointer"
              >{{ scope.row.tcSum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="gmtCreate" label="创建时间" sortable="custom">
          <template slot-scope="scope">
            {{ Dateformat(scope.row.gmtCreate, "YYYY-MM-DD HH:mm") || "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="username" label="创建人"></el-table-column>
        <el-table-column fixed="right" label="操作" width="300">
          <template slot-scope="scope">
            <el-button @click="openPopup(scope.row)" type="text"
              >添加时长</el-button
            >
            <el-button @click="openRecord(2, scope.row)" type="text"
              >充值记录</el-button
            >
            <el-button @click="openRecord(3, scope.row)" type="text"
              >消费记录</el-button
            >
            <el-button @click="addAgent(false, scope.row)" type="text"
              >编辑</el-button
            >
            <el-button
              @click="removeAgent(scope.row)"
              type="text"
              style="color: #c91313"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="pageSize"
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="totalNum"
          @current-change="handelCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-row>
    </div>
    <el-dialog
      class="commonDialog"
      :title="(addAgentType ? '添加' : '编辑') + '代理'"
      :visible.sync="popupOn.addAgent"
      width="500px"
      @close="addAgentDown"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="代理名称"
          prop="name"
          :rules="{
            required: true,
            message: '请输入代理名称',
            trigger: 'blur',
          }"
        >
          <el-input
            class="waicengPhone"
            v-model="ruleForm.name"
            placeholder="请输入代理名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="phone"
          :rules="{
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          }"
        >
          <el-input
            class="waicengPhone"
            v-model="ruleForm.phone"
            placeholder="请输入手机号"
            clearable
            :disabled="!addAgentType"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 50px">
          <el-button type="primary" plain @click="popupOn.addAgent = false"
            >取消</el-button
          >
          <el-button type="primary" @click="resetForm('ruleForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      class="commonDialog"
      title="添加时长"
      :visible.sync="popupOn.addTime"
      width="650px"
    >
      <div class="userInfo flex">
        <div>代理名称: {{ addDurationData.row.name }}</div>
        <div>手机号: {{ addDurationData.row.phone }}</div>
      </div>
      <div class="radio-group">
        <el-radio-group v-model="addDurationData.type">
          <el-radio
            v-for="item in addDurationData.list"
            :key="item.id"
            :label="item.type"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
        <div class="flex">
          <el-input
            class="waicengPhone"
            v-model="addDurationData.time"
            placeholder="请输入时长"
            clearable
            v-Int
            @input="limitation"
            style="width: 80% !important"
          ></el-input>
          分钟
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="popupOn.addTime = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="submitTo">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="commonDialog"
      :title="recordPopup.recordType == 2 ? '充值记录' : '消费记录'"
      :visible.sync="recordPopup.show"
      width="900px"
      @close="recordPopupDown"
    >
      <recordPopup
        ref="record"
        :typeShow="recordPopup.recordType"
        :detailData="recordPopup.res"
      ></recordPopup>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import recordPopup from "../../components/recordPopup.vue";
export default {
  components: { recordPopup },
  props: [],
  data() {
    return {
      searchEle: {},
      tableData: [],
      totalNum: 0,
      currentPage: 1,
      pageSize: 10,
      popupOn: {
        addAgent: false,
        addTime: false,
      },
      addAgentType: false,
      ruleForm: {},
      addDurationData: {
        type: "VIDEO_TIME",
        time: null,
        row: {},
        expirationDate: 1,
        list: [
          {
            name: "短视频时长",
            id: 1,
            type: "VIDEO_TIME",
          },
          {
            name: "无人直播时长",
            id: 2,
            type: "CREDIT_TIME",
          },
          {
            name: "真人驱动直播时长",
            id: 3,
            type: "LIVE_RP_TIME",
          },
        ],
      },
      sortType: "DESC",
      recordPopup: {
        recordType: 2,
        show: false,
        res: {},
      },
      sortParams: null,
    };
  },
  watch: {},
  created() {
    this.getAgencyManagementList();
  },
  methods: {
    limitation(){
      if(this.addDurationData.time>50000){
        this.$message('客户单次添加时长不超过50000分钟')
        this.addDurationData.time = null;
      }
    },
    //跳转客户详情
    goTo(id) {
      this.$router.push("/Home/agent/customerDetails?amId=" + id);
    },
    //触发排序
    sortchange(v) {
      if (v.order) {
        this.sortParams = {
          "sortFieldRequest.sortType": v.order == "ascending" ? "ASC" : "DESC",
          "sortFieldRequest.sortField": v.prop,
        };
      } else {
        this.sortParams = null;
      }
      this.getAgencyManagementList();
    },
    //打开添加时长弹窗
    openPopup(row) {
      this.addDurationData.row = row;
      this.popupOn.addTime = true;
    },
    //添加时长提交
    submitTo() {
      let resData = this.addDurationData;
      if (resData.time) {
        let params = {
          durationType: resData.type,
          amId: resData.row.amId,
          remainingTime: +resData.time * 60,
        };
        this.http
          .post("/agencyManagement/addDuration", params)
          .then((res) => {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.popupOn.addTime = false;
            resData.time = null;
            resData.expirationDate = 1;
            this.getAgencyManagementList();
          })
          .catch(() => {
            resData.time = null;
            resData.expirationDate = 1;
          });
      } else {
        this.$message.error("请完善信息!");
        return;
      }
    },
    //打开充值/消费记录弹窗并赋值
    openRecord(type, row) {
      this.recordPopup.recordType = type;
      this.recordPopup.res = row;
      this.recordPopup.show = true;
      setTimeout(() => {
        this.$refs.record.requestGudgment();
      }, 200);
    },
    //关闭充值记录弹窗
    recordPopupDown() {
      this.$refs.record.searchEle = {};
    },
    //添加/编辑代理提交
    resetForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          let params = this.ruleForm;
          let url = this.addAgentType
            ? "addAgencyManagement"
            : "updateAgencyManagement";
          this.http
            .post("/agencyManagement/" + url, params)
            .then((res) => {
              if (res.data.success) {
                this.$message.success(
                  this.addAgentType ? "添加成功" : "编辑成功"
                );
                this.popupOn.addAgent = false;
                this.getAgencyManagementList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    //打开添加/编辑代理弹窗
    addAgent(type, row) {
      this.addAgentType = type;
      if (!type) {
        this.ruleForm = {
          name: row.name,
          phone: row.phone,
          id: row.id,
        };
      } else {
        this.ruleForm = {};
      }
      this.popupOn.addAgent = true;
    },
    //关闭代理弹窗
    addAgentDown() {
      this.$refs.ruleForm.resetFields();
    },
    //删除代理
    removeAgent(row) {
      this.$confirm(
        "该代理帐户下，还有剩余时长未使用，确定要删除吗？此操作不可逆，请慎重操作！",
        "删除代理客户",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let params = {
            id: row.id,
          };
          this.http
            .post("/agencyManagement/deleteAgencyManagement", params)
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getAgencyManagementList();
              }
            });
        })
        .catch(() => {});
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getAgencyManagementList();
    },
    //翻页
    handelCurrentChange(page) {
      this.currentPage = page;
      this.getAgencyManagementList();
    },
    //导出数据
    async exportOn() {
      let requestData = await this.returnUrl();
      axios
        .get(
          requestData.config.baseURL +
            "/agencyManagement/exportAgencyManagementRechargeRecord",
          {
            timeout: 6000000,
            headers: requestData.config.headers,
            responseType: "blob", //重要
            params: this.returnParams(),
          }
        )
        .then((res) => {
          console.log(res);
          this.toExport(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取代理管理列表
    getAgencyManagementList() {
      this.http
        .get("/agencyManagement/getAgencyManagementList", this.returnParams())
        .then((res) => {
          this.tableData = res.data.data;
          this.totalNum = res.data.totalRecords;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //筛选项
    returnParams() {
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      params = Object.assign({}, this.searchEle, params);
      if (params.time) {
        params.startTime = params.time[0];
        params.endTime = params.time[1];
        delete params.time;
      }
      if (this.sortParams) {
        params = Object.assign({}, params, this.sortParams);
      }
      return params;
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.radio-group {
  .flex {
    width: 250px;
    margin-top: 30px;
    color: white;
    font-size: 14px;
  }
  .el-radio {
    color: #d3d3d3;
  }
  padding: 20px 0;
}
.userInfo {
  font-size: 16px;
  color: white;
  justify-content: flex-start;
  div {
    margin-right: 40px;
  }
  border-bottom: 1px solid #1a1f58;
  padding-bottom: 20px;
}
.container {
  .view {
    .title {
      color: white;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    margin-top: 20px;
  }
  margin: auto;
}
</style>
